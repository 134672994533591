import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { getFlex, linkRouge, palette } from "../../styles/styles";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";

const Slides = (props) => {
  const { className } = props;
  const { t } = useTranslation("executiveSearch");

  return (
    <section className={className}>
      <div className="right">
        <p className="redLabel">{t("slidesRedLabel")}</p>
        <h2>{t("slidesTitle")}</h2>
        <p className="desc">{t("slidesDesc")}</p>
        <Link className="link" to="/pre-screening">
          {t("slidesButton")}
        </Link>
        <StaticImage
          className="mobileImg"
          src="../../images/carousel/exec.png"
          alt=""
        />
      </div>
      <div className="left">
        <StaticImage
          className="img"
          src="../../images/carousel/exec.png"
          alt=""
        />
      </div>
    </section>
  );
};

export default styled(Slides)`
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: max-content;
  .left {
    background-color: ${palette.bgGrey};
    ${getFlex("row", "ct", "ct")};
    padding: 4rem;
    .img {
      width: 75%;
    }
  }
  .right {
    display: grid;
    grid-template-rows: repeat(3, max-content) 1fr;
    justify-items: flex-start;
    align-items: center;
    grid-row-gap: 2rem;
    background-color: ${palette.bgGrey};
    padding: 8% 0 4% 24%;
    .redLabel {
      font-family: SofiaProSemiBoldAz, sans-serif;
      color: ${palette.red};
      font-size: 2rem;
    }
    h2 {
      font-size: 2.8rem;
      margin-top: -2rem;
      text-align: left;
    }
    p {
      font-size: 1.6rem;
      font-family: SofiaProLightAz, sans-serif;
    }
    .desc {
      flex-grow: 0.5;
    }
    .link {
      ${linkRouge};
      align-self: flex-end;
      margin-bottom: 2rem;
    }
    .mobileImg {
      display: none;
    }
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    .left {
      display: none;
    }
    .right {
      padding: 10% 8%;
      height: fit-content;
      grid-template-rows: repeat(4, max-content);
      justify-items: center;
      align-items: center;
      grid-row-gap: 3rem;
      h2 {
        width: 70%;
        text-align: center !important;
      }
      p {
        width: 70%;
        text-align: center;
      }
      .mobileImg {
        display: flex;
        width: 60%;
      }
    }
  }
`;
