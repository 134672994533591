import React from "react";
import styled from "styled-components";
import Image from "../../ImageQuerys/SlideImages";
import { getFlex } from "../../styles/styles";
import { useTranslation } from "gatsby-plugin-react-i18next";

const MoreInfo = (props) => {
  const { className } = props;
  const { t } = useTranslation("executiveSearch");

  return (
    <section className={className}>
      <div className="up">
        <Image className="imgUp" imageName="exec1.png" />
        <div className="info">
          <h2>{t("moreInfoFirstTitle")}</h2>
          <p>{t("moreInfoFirstDesc")}</p>
        </div>
        <Image className="mobileImg" imageName="exec1.png" />
      </div>
      <div className="down">
        <div className="info">
          <h2>{t("moreInfoSecondTitle")}</h2>
          <p>{t("moreInfoSecondDesc")}</p>
        </div>
        <Image className="imgDown secondImg" imageName="exec2.png" />
      </div>
    </section>
  );
};

export default styled(MoreInfo)`
  padding: 6% 12%;
  height: fit-content;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  grid-row-gap: 8rem;
  .up, .down {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    @media(max-width: 600px){
      grid-template-columns: 1fr;
      .info{
        ${getFlex("column", "sa", "ct")};
        grid-row-gap: 2rem;
        h2, p {
          text-align: center;
        }
      }
    }
  }
  .mobileImg{
    display: none;
  }
  .imgUp, .imgDown {
    width: 75%;
  }
  .secondImg{
    margin-left: auto;
  }
  h2 {
    font-size: 3.8rem;
    text-align: left;
  }
  p {
    font-family: SofiaProLightAz, sans-serif;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  @media(max-width: 600px){
    padding: 10% 8%;
    height: fit-content;
    grid-row-gap: 6rem;
    .up, .down{
      justify-items: center;
      grid-row-gap: 2rem;
      .secondImg{
        margin: 0;
      }
    }
    .imgUp {
      display: none;
    }
    .mobileImg, .imgDown{
      display: flex;
      width: 80%;
    }
  }
`;
