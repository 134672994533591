import React from "react";
import styled from "styled-components";
import { getFlex } from "../../styles/styles";
import Image from "../../ImageQuerys/InfoImages";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Info = (props) => {
  const { className } = props;
  const { t } = useTranslation(["executiveSearch"]);

  const data = [
    { img: "infoExec.png", title: "infoFirstTitle", desc: "infoFirstDesc" },
    { img: "infoExec1.png", title: "infoSecondTitle", desc: "infoSecondDesc" },
    { img: "infoExec2.png", title: "infoThirdTitle", desc: "infoThirdDesc" },
  ];

  return (
    <section className={className}>
      <div className="intro">
        <h2>{t("infoTitle")}</h2>
        <p>{t("infoDesc")}</p>
      </div>
      <div className="items">
        {data.map((item) => (
          <div className="item">
            <Image className="icon" imageName={item.img} />
            <h5>{t(item.title)}</h5>
            <p>{t(item.desc)}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default styled(Info)`
  padding: 8% 16%;
  height: fit-content;
  ${getFlex("column", "ct", "ct")};
  row-gap: 4rem;
  @media(max-width: 600px){
    padding: 10% 8%;
  }
  .intro {
    ${getFlex("column", "sb", "ct")};
    h2 {
      font-size: 4.8rem;
      text-align: center;
      margin-bottom: 3rem;
    }
    p {
      text-align: center;
      width: 75%;
      font-size: 2rem;
      font-family: SofiaProLightAz, sans-serif;
    }
  }
  .items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16rem;
    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
    }
    .item {
      display: grid;
      grid-template-rows: 2fr 1fr 3fr;
      justify-items: center;
      align-content: center;
      @media (max-width: 768px) {
        grid-template-rows: repeat(3, max-content);
        grid-row-gap: .5rem;
      }
      .icon {
        width: 14rem;
        img {
          object-fit: contain !important;
        }
        @media (max-width: 768px) {
          width: 12rem;
        }
      }
      h5 {
        font-size: 2rem;
        text-align: center;
        margin-bottom: 1rem;
      }
      p {
        font-family: SofiaProLightAz, sans-serif;
        font-size: 1.6rem;
        line-height: 3.2rem;
        text-align: center;
        @media (max-width: 768px) {
          width: 60%;
        }
      }
    }
  }
`;
