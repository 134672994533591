import React from "react";
import styled from 'styled-components';
import Layout from "../comps/reusable/Layout";
import HeroSection from "../comps/reusable/HeroSection";
import Info from "../comps/executive-search/Info";
import Slides from "../comps/executive-search/Slides";
import Partners from "../comps/reusable/Partners";
import Prefooter from "../comps/reusable/Prefooter";
import MoreInfo from "../comps/executive-search/MoreInfo";
import {graphql} from "gatsby";

const Headhunting = () => {

  return (
    <Layout>
      <HeroSection
        data={{
          imageName: "heroExecutive.png",
          hasButtons: true,
        }}
        nameSpace="executiveSearch"
      />
      <Info/>
      <Slides/>
      <Partners  logos={[
        "partner1.png",
        "partner5.png",
        "partner4.png",
        "partner2.png",
        "partner3.png",
      ]}/>
      <MoreInfo/>
      <Prefooter
        nameSpace="executiveSearch"
        isFromExec
      />
    </Layout>
  );
}

export default styled(Headhunting)``;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;